import './App.css';
import CityInput from './Components/CityInput/CityInput';
import Header from './Components/Header/Header';
import Meteo from './Components/Meteo/Meteo';

function App() {
  return (
    <>
    <Header />
    <Meteo />
    <CityInput />
    </>
  );
}

export default App;
